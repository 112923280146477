//
// Google font - Roboto
//

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600');
@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../../../fonts/MuseoSans-700.ttf') format('truetype');
}